<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            {{
                sendOperation ? 'Send To Next Process' : 'Receive Items'
            }}</v-card-title
        >
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mb-2 mx-1 mt-3">
                <v-col v-if="sendOperation">
                    <v-row no-gutters class="mr-2">
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            class="d-flex align-center"
                        >
                            <v-combobox
                                v-model="nextProcess"
                                hide-details
                                label="Destination Process *"
                                prepend-icon="mdi-tools"
                                :items="availableProcesses"
                                item-text="name"
                                :rules="[rules.required]"
                                required
                                class="mb-2 mr-5"
                                :disabled="availableProcesses.length == 1"
                                @change="calculateValues()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            class="d-flex align-center"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="mr-2"
                                    >mdi-database-clock-outline</v-icon
                                >
                                <p class="ma-0">
                                    Pending Quantity: {{ pendingQty }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mr-2">
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            class="d-flex align-center"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="mr-2"
                                    >mdi-database-outline</v-icon
                                >
                                <p class="ma-0">
                                    Available Quantity: {{ availableQty }}
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6">
                            <v-text-field
                                v-model="qtyToSend"
                                prepend-icon="mdi-database-export-outline"
                                label="Quantity To Send*"
                                type="number"
                                required
                                :rules="[rules.required, rules.minValue]"
                                class="mt-4 pa-0"
                                @change="validateQty"
                                :max="availableQty"
                            />
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6">
                            <v-text-field
                                v-model="qtyToSend"
                                prepend-icon="mdi-database-export-outline"
                                label="Quantity To Send*"
                                type="number"
                                required
                                :rules="[rules.required, rules.minValue]"
                                class="mt-4 pa-0"
                                @change="validateQty"
                                :max="availableQty"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex align-center">
                            <v-combobox
                                v-model="selectedAssembly"
                                hide-details
                                label="Destination Assembly Work Order*"
                                prepend-icon="mdi-robot-industrial"
                                :items="availableAssemblies"
                                item-text="code"
                                :rules="[rules.required]"
                                required
                                class="mb-5"
                                :disabled="availableAssemblies.length == 1"
                                v-if="
                                    nextProcess && nextProcess.assemblyProcess
                                "
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-else>
                    <v-data-table
                        id="virtual-scroll-table"
                        disable-pagination
                        hide-default-footer
                        :headers="filterHeaders"
                        :items="filteredItems"
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        fixed-header
                        :style="{ width: '100%' }"
                        :height="
                            $vuetify.breakpoint.mdAndDown ? '70vh' : '35vh'
                        "
                        item-key="index"
                        :loading="loading"
                    >
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                                :style="{ 'background-color': '#eeeeee' }"
                            >
                                <v-col cols="9">
                                    <h2 class="ml-4">PENDING DELIVERIES</h2>
                                    <v-spacer />
                                </v-col>
                            </v-row>
                        </template>
                        <!--HEADERS-->
                        <template v-slot:[`header.selected`]="{ header }">
                            <div class="d-flex align-center justify-center">
                                <p class="ma-0 pr-2">{{ header.text }}</p>
                                <v-simple-checkbox
                                    color="primary"
                                    v-model="selectAll"
                                    class="d-flex align-center justify-center"
                                    @click="selectAllItems"
                                ></v-simple-checkbox>
                            </div>
                        </template>
                        <template v-slot:[`header.partNumber`]="{ header }">
                            <v-text-field
                                class="py-2 mt-2 custom-label"
                                :label="header.text"
                                v-model="partNumberToFilter"
                                dense
                            />
                        </template>
                        <template v-slot:[`header.originProcess`]="{ header }">
                            <v-text-field
                                class="py-2 mt-2 custom-label"
                                :label="header.text"
                                v-model="originProcessToFilter"
                                dense
                            />
                        </template>
                        <template
                            v-slot:[`header.destinationProcess`]="{ header }"
                        >
                            <v-text-field
                                class="py-2 mt-2 custom-label"
                                :label="header.text"
                                v-model="destinationProcessToFilter"
                                dense
                            />
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.originProcess`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0 text-capitalize">
                                    {{ item.prevProcess.name }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.destinationProcess`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0 text-capitalize">
                                    {{ item.nextProcess.name }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.deliveredBy`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0 text-capitalize">
                                    {{ getUserName(item.deliveredBy) }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.deliveredOn`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0 text-capitalize">
                                    {{
                                        formatDate(
                                            item.deliveredOn._seconds ||
                                                item.deliveredOn.seconds
                                        )
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.qtyToReceive`]="{ item }">
                            <div class="d-flex justify-center">
                                <v-text-field
                                    type="number"
                                    class="my-0"
                                    v-model="item.qtyToReceive"
                                    :rules="[
                                        () =>
                                            rules.maxValue(
                                                item.qtyToReceive,
                                                item
                                            ),
                                    ]"
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.locationItem`]="{ item }">
                            <div
                                class="d-flex justify-center"
                                v-if="
                                    item.nextProcess &&
                                        (item.nextProcess.id ===
                                            packingProcess.id ||
                                            item.nextProcess.id ==
                                                readyForPackingProcess.id)
                                "
                            >
                                <v-text-field
                                    type="text"
                                    class="my-0"
                                    v-model="item.locationItem"
                                    :rules="[() => validateLocation(item)]"
                                >
                                </v-text-field>
                            </div>
                            <div class="d-flex justify-center" v-else>
                                <p type="text" class="my-0">
                                    N\A
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.selected`]="{ item }">
                            <div class="d-flex justify-center">
                                <v-simple-checkbox
                                    color="primary"
                                    v-model="item.selected"
                                    @click="verifySelected"
                                ></v-simple-checkbox>
                            </div>
                        </template>
                        <!--FOOTER-->
                        <!-- <template v-slot:[`body.append`]>
                            <tr style="background-color: #eeeeee">
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold">
                                    TOTALS
                                </td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                            </tr>
                        </template> -->
                    </v-data-table>

                    <div class="d-flex align-center mb-2 mt-4">
                        <v-icon>
                            mdi-account-arrow-left
                        </v-icon>
                        <h3 class="ml-2">Receiving Operator</h3>
                    </div>

                    <v-row no-gutters>
                        <v-col cols="12" xl="6" lg="6" md="6">
                            <v-text-field
                                v-model="code"
                                type="password"
                                hide-details
                                label="User Code"
                                :rules="[rules.required]"
                                required
                                class="pl-1 pr-4 pt-1"
                            />
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6">
                            <v-text-field
                                v-model="confirmCode"
                                type="password"
                                hide-details
                                label="Confirm Code"
                                :rules="[rules.required, matchCode]"
                                required
                                class="pl-1 pt-1"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="buttonConditions()"
                @click="action"
                :loading="loading"
            >
                {{ sendOperation ? 'send' : 'save' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import cryptoJs from 'crypto-js'
import moment from 'moment'

export default {
    name: 'NextProcess',
    props: {
        item: {
            type: Object,
            required: false,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        sendOperation: { type: Boolean, required: true },
    },

    data: () => ({
        valid: false,
        loading: false,
        prevProcess: undefined,
        nextProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        availableQty: 0,
        pendingQty: 0,
        qtyToSend: undefined,
        processes: [],
        rules: {
            required: v => !!v || 'The value is required',
            minValue: v => v > 0 || 'The value must be greater than 0',
            number: v => v == '' || v > 0 || 'Not valid',
            maxValue: (v, item) => {
                let min = 0
                let max = 0
                if (item.qty > 0) {
                    max = item.qty
                } else {
                    min = item.qty
                }
                if (Number(v || 0) > max) {
                    return `The value cannot be greater than ${max}.`
                } else if (Number(v || 0) < min) {
                    return `The value cannot be less than ${min}.`
                } else {
                    return true
                }
            },
        },
        availableProcesses: [],
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'readyForPacking',
        },
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        availableAssemblies: [],
        selectedAssembly: null,
        headers: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ORIGIN PROCESS',
                value: 'originProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESTINATION PROCESS',
                value: 'destinationProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY DELIVERED',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY TO RECEIVE',
                value: 'qtyToReceive',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERED BY',
                value: 'deliveredBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERED ON',
                value: 'deliveredOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SELECTED',
                value: 'selected',
                align: 'center',
                sortable: false,
            },
        ],
        partNumberToFilter: null,
        deliveries: [],
        selectAll: false,
        users: [],
        originProcessToFilter: null,
        destinationProcessToFilter: null,
    }),
    computed: {
        filterHeaders() {
            let headers = _.cloneDeep(this.headers)
            if (
                this.filteredItems.some(
                    item =>
                        item.nextProcess &&
                        (item.nextProcess.id === this.packingProcess.id ||
                            item.nextProcess.id ==
                                this.readyForPackingProcess.id)
                )
            ) {
                headers.splice(7, 0, {
                    text: 'LOCATION',
                    value: 'locationItem',
                    align: 'center',
                    sortable: false,
                })
            }
            return headers
        },
        filteredItems() {
            let conditions = []

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            if (this.originProcessToFilter) {
                conditions.push(this.filterByOriginProcess)
            }

            if (this.destinationProcessToFilter) {
                conditions.push(this.filterByDestinationProcess)
            }

            conditions.push(this.filterByPending)

            if (conditions.length > 0) {
                return this.deliveries.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.deliveries
        },
    },

    async mounted() {
        try {
            this.loading = true
            if (this.item && this.item.processes) {
                this.item.processes.forEach(processId => {
                    let process = this.item.dataProcesses.find(
                        p => p.id == processId
                    )
                    if (
                        !process &&
                        this.workOrder.assemblyWorkOrder != undefined
                    ) {
                        if (processId == this.qualityProcess) {
                            process = this.qualityProcess
                        } else if (processId == this.packingProcess) {
                            process = this.packingProcess
                        }
                    }
                    if (process) {
                        this.processes.push(process)
                    }
                })
                this.availableProcesses = _.cloneDeep(this.processes)
                const processIndex = this.processes.findIndex(
                    p => p.id == this.user.process.id
                )
                if (processIndex > -1) {
                    this.prevProcess = this.processes[processIndex]
                    if (processIndex + 1 < this.processes.length) {
                        this.nextProcess = this.processes[processIndex + 1]
                        if (
                            this.nextProcess.id == this.qualityProcess.id &&
                            processIndex + 2 < this.processes.length
                        ) {
                            this.nextProcess = this.processes[processIndex + 2]
                        }
                    }
                    this.availableProcesses.splice(0, processIndex + 1)
                    if (processIndex == this.processes.length - 1) {
                        this.availableProcesses = [this.packingProcess]
                        this.nextProcess = this.packingProcess
                    } else if (processIndex == this.processes.length - 1) {
                        this.availableProcesses = []
                    }
                }
                const qualityIndex = this.availableProcesses.findIndex(
                    p => p.id == this.qualityProcess.id
                )
                if (qualityIndex > -1) {
                    this.availableProcesses.splice(qualityIndex, 1)
                }
                this.calculateValues()
            }
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.deliveries = []
            let count = 0
            if (!this.sendOperation) {
                this.workOrder.items.forEach(item => {
                    if (item.deliveries) {
                        item.deliveries.forEach(delivery => {
                            delivery.partNumber = item.partNumber
                            delivery.id = item.id
                            delivery.qtyToReceive = delivery.qty
                            delivery.index = count
                            delivery.partNumberId = item.partNumberId
                            count++
                        })
                        this.deliveries.push(...item.deliveries)
                    }
                })
            }
            this.availableAssemblies = this.item.assemblyWorkOrders
                ? this.item.assemblyWorkOrders
                : []
            if (this.availableAssemblies.length == 1) {
                this.selectedAssembly = this.availableAssemblies[0]
            }
            this.calculateValues()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculateValues() {
            try {
                let totalProduced = 0
                let totalDelivered = 0
                let totalReleased = 0

                if (this.nextProcess) {
                    let prevProcess = {}
                    if (
                        this.workOrder.assemblyWorkOrder == undefined &&
                        this.nextProcess == this.packingProcess
                    ) {
                        prevProcess = this.qualityProcess.id
                    } else {
                        const index = this.item.processes.findIndex(
                            p => p == this.nextProcess.id
                        )
                        prevProcess = this.item.processes[index - 1]
                    }

                    if (this.item.deliveries) {
                        let delivered = this.item.deliveries.filter(
                            register =>
                                register.prevProcess.id == this.prevProcess.id
                        )
                        totalDelivered = delivered.reduce(
                            (total, register) =>
                                total +
                                (register.receivedBy ? register.qty : 0),
                            0
                        )
                        this.pendingQty = delivered.reduce(
                            (total, register) =>
                                total +
                                (!register.receivedBy ? register.qty : 0),
                            0
                        )
                    }

                    if (prevProcess == this.qualityProcess.id) {
                        if (this.item.quality) {
                            totalReleased = this.item.quality.reduce(
                                (total, register) =>
                                    total + (Number(register.releaseQty) || 0),
                                0
                            )
                        }
                        this.availableQty =
                            totalReleased - totalDelivered - this.pendingQty
                    } else {
                        if (this.item.production) {
                            let produced = this.item.production.filter(
                                register =>
                                    register.process.id == this.prevProcess.id
                            )
                            totalProduced = produced.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        this.availableQty =
                            totalProduced - totalDelivered - this.pendingQty
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },
        validateQty() {
            if (this.qtyToSend > this.availableQty) {
                this.qtyToSend = this.availableQty
            }
        },
        async send() {
            try {
                this.loading = true
                let params = {
                    partNumberId: this.item.partNumberId,
                    workOrderId: this.workOrder.id,
                    qty: this.qtyToSend,
                    prevProcess: this.prevProcess,
                    nextProcess: this.nextProcess,
                }
                if (this.nextProcess.assemblyProcess) {
                    params.destinationAssemblyWorkOrder = this.selectedAssembly
                }
                const items = await API.sendToNextProcess(params)
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        selectAllItems() {
            const items = this.filteredItems.map(i => i.id)
            this.deliveries.forEach(i => {
                if (items.includes(i.id)) {
                    i.selected = this.selectAll
                }
            })
            this.deliveries = _.cloneDeep(this.deliveries)
        },
        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },
        filterByPending(item) {
            return !item.receivedBy
        },
        filterByDestinationProcess(item) {
            return (
                item.nextProcess.name &&
                item.nextProcess.name
                    .toLowerCase()
                    .includes(this.destinationProcessToFilter.toLowerCase())
            )
        },
        filterByOriginProcess(item) {
            return (
                item.prevProcess.name &&
                item.prevProcess.name
                    .toLowerCase()
                    .includes(this.originProcessToFilter.toLowerCase())
            )
        },
        getUserName(userId) {
            const user = this.users.find(u => u.id == userId)
            let name = ''
            if (user) {
                name = user.name
            }
            return name
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD HH:mm')}`
        },
        verifySelected() {
            if (this.filteredItems.length > 0) {
                this.selectAll = !this.filteredItems.find(
                    i => i.selected != true
                )
            } else {
                this.selectAll = false
            }
        },
        action() {
            if (this.sendOperation) {
                this.send()
            } else {
                this.receive()
            }
        },
        async receive() {
            try {
                this.loading = true
                const encryptedCode = cryptoJs.AES.encrypt(
                    this.code,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                ).toString()
                this.code = encryptedCode
                let params = {
                    items: this.filteredItems.filter(i => i.selected),
                    receivingSignCode: this.code,
                    workOrderId: this.workOrder.id,
                }

                const items = await API.receiveItems(params)
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        buttonConditions() {
            return (
                !this.valid ||
                (!this.sendOperation &&
                    this.filteredItems.filter(i => i.selected).length == 0)
            )
        },
        validateLocation(item) {
            if (item.nextProcess.id === 'packing' && item.selected) {
                return !!item.locationItem || 'The value is required'
            }
            return true
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
</style>
